import { Injectable } from '@angular/core';
import { pick } from 'lodash';
import { Subject } from 'rxjs';

import { CoreAPILoader } from 'src/app/ajs-upgraded-providers';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class DisplayApiService {

  static readonly DISPLAY_WRITABLE_FIELDS = [
    'name', 'status', 'useCompanyAddress', 'addressDescription', 'street',
    'unit', 'city', 'province', 'country', 'postalCode', 'timeZoneOffset',
    'restartEnabled', 'restartTime', 'autostartOnBoot', 'width',
    'height', 'orientation', 'monitoringEnabled', 'monitoringEmails', 'monitoringSchedule',
    'activationKey', 'overrideTakeoverAllowed', 'displayControl', 'favoredBy',
    'screenSharingEnabled', 'screenSharingMode', 'screenSharingPinRequired'
  ];

  displaysLoadedSubject: Subject<Array<any>> = new Subject();

  constructor(
    private coreAPILoader: CoreAPILoader,
    private companyStateService: CompanyStateService
  ) { }

  list (search, cursor?) {
    const obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'search': search.query || '*',
      'filter': search.filter || '',
      'includeSubcompanies': search.includeSubcompanies || false,
      'cursor': cursor,
      'count': search.count,
      'sort': search.sortBy + (search.reverse ? ' desc' : ' asc')
    };
    console.debug('list displays called with', obj);

    return this.coreAPILoader().then(coreApi => {
      return coreApi.display.list(obj);
    })
    .then(resp => {
      let result = resp.result;
      result.items && result.items.forEach(function (item) {
        item.lastActivityDate = item.onlineStatus === 'online' ? new Date() : (item
          .lastActivityDate ? new Date(item.lastActivityDate) : '');
      });

      this.displaysLoadedSubject.next(result.items);

      return resp.result;
    })
    .catch(e => {
      console.error('Failed to get list of displays.', e);
      throw e;
    });
  }

  get (displayId?) {
    const obj = {
      'id': displayId
    };

    console.debug('get display called with', displayId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.display.get(obj);
      })
      .then((resp) => {
        console.debug('get display resp', resp);

        const item = resp.result.item;
        if (item) {
          item.lastActivityDate = item.onlineStatus === 'online' ? new Date() : (item
            .lastActivityDate ?
            new Date(item.lastActivityDate) : '');

          if (item.overrideTakeoverAllowed === undefined) {
            item.overrideTakeoverAllowed = true;
          }

          if (!!item.screenSharingMode) {
            item.screenSharingStandard = item.screenSharingMode.includes('standard');
            item.screenSharingModerated = item.screenSharingMode.includes('moderated');
          }

          item.autostartOnBoot = item.autostartOnBoot === undefined ? true : item.autostartOnBoot;

          this.displaysLoadedSubject.next([item]);
        }

        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to get display.', e);
        throw e;
      });
  }

  add (display) {

    const fields = pick.apply(this, [display].concat(DisplayApiService.DISPLAY_WRITABLE_FIELDS));
    fields.assignLicense = display.playerProAuthorized;
    const obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'data': fields
    };

    return this.coreAPILoader().then((coreApi) => {
        return coreApi.display.add(obj);
      })
      .then((resp) => {
        console.debug('added display', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to add display.', e);
        throw e;
      });
  }

  update (displayId, display) {
    const fields = pick.apply(this, [display].concat(
      DisplayApiService.DISPLAY_WRITABLE_FIELDS));
    const obj = {
      'id': displayId,
      'data': fields
    };

    console.debug('update display called with', displayId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.display.patch(obj);
      })
      .then((resp) => {
        console.debug('update display resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to update display.', e);
        throw e;
      });
  }

  delete (displayId?) {
    const obj = {
      'id': displayId
    };

    console.debug('delete display called with', displayId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.display.delete(obj);
      })
      .then((resp) => {
        console.debug('delete display resp', resp);
        return resp;
      })
      .catch((e) => {
        console.error('Failed to delete display.', e);
        throw e;
      });
  }

  restart (displayId?) {
    const obj = {
      'id': displayId
    };

    console.debug('restart display called with', displayId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.display.restart(obj);
      })
      .then((resp) => {
        console.debug('restart display resp', resp);
        return resp;
      })
      .catch((e) => {
        console.error('Failed to restart display.', e);
        throw e;
      });
  }

  reboot (displayId?) {
    const obj = {
      'id': displayId
    };

    console.debug('reboot display called with', displayId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.display.reboot(obj);
      })
      .then((resp) => {
        console.debug('reboot display resp', resp);
        return resp;
      })
      .catch((e) => {
        console.error('Failed to reboot display.', e);
        throw e;
      });
  }

  requestScreenshot (displayId, clientId) {
    console.debug('request screenshot called with', displayId);

    return this.coreAPILoader()
      .then((coreApi) => {
        return coreApi.display.requestScreenshot({
          id: displayId,
          clientId: clientId
        });
      })
      .then((resp) => {
        console.debug('request screenshot resp', resp);
        return resp;
      })
      .catch((e) => {
        console.error('Failed screenshot request', e);
        throw e;
      });
  }

  uploadControlFile (displayId, controlFileContents) {
    console.debug('uploadControlFile called with', displayId, controlFileContents);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.display.uploadControlFile({
          'id': displayId,
          'controlFileContents': controlFileContents
        });
      })
      .then((resp) => {
        console.debug('uploadControlFile resp', resp);
        return resp;
      })
      .catch((e) => {
        console.error('Failed to upload control file.', e);
        throw e;
      });
  }

  hasFreeDisplays (companyId?, displayIds?) {
    console.debug('hasFreeDisplays called with', companyId, displayIds);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.display.hasFreeDisplays({
          'companyId': companyId,
          'data': {
            'displayIds': displayIds
          }
        });
      })
      .then((resp) => {
        console.debug('hasFreeDisplays resp', resp);
        return resp.result && resp.result.items;
      })
      .catch((e) => {
        console.error('Failed to retrieve hasFreeDisplays.', e);
        throw e;
      });
  }

  summary (companyId?: string) {
    companyId = companyId || this.companyStateService.getSelectedCompanyId();
    console.debug('summary called with', companyId);

    return this.coreAPILoader().then((coreApi) => {
        return coreApi.display.summary({
          'companyId': companyId,
          'includeSubcompanies': false
        });
      })
      .then((resp) => {
        console.debug('summary resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to retrieve summary.', e);
        throw e;
      });
  }

  export () {
    const companyId = this.companyStateService.getSelectedCompanyId();

    console.debug('export called with', companyId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.display.export({
          'companyId': companyId
        });
      })
      .then((resp) => {
        console.debug('export resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to export displays.', e);
        throw e;
      });
  }

  move (displayId?, companyId?) {
    console.debug('move called with', companyId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.display.move({
          'id': displayId,
          'companyId': companyId
        });
      })
      .then((resp) => {
        console.debug('move resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to move displays.', e);
        throw e;
      });
  }
}